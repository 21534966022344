.multi-image-header {
    display: flex;
    flex-direction: row;
    &__left {
        width: 50%;
    }
    &__right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;

        .rightImage {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 28%;
        }
    }
}


@media (max-width: 990px) { 
    .multi-image-header {
        flex-direction: column;
        &__left {
            width: 100%;
        }
        &__right {
            .rightImage {
                width: 33%;
            }
        }
    }
}

@media (max-width: 480px) { 
    .multi-image-header {
        &__right {
            .rightImage {
                width: 40%;
            }
        }
    }
}