.c-item-list {
  position: relative;
  padding: 0; // width: 100%;
  margin: 10px 20px;
  &--left:before {
    position: absolute;
    left: 0;
    top: -5px;
    content: " ";
    display: block;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background: rgb(213, 213, 213);
    background: linear-gradient( to bottom, rgba(213, 213, 213, 0) 0%, rgb(213, 213, 213) 8%, rgb(213, 213, 213) 92%, rgba(213, 213, 213, 0) 100%);
    z-index: 5;
  }
  &--right:before {
    position: absolute;
    right: 0;
    top: -5px;
    content: " ";
    display: block;
    width: 2px;
    height: 100%;
    margin-right: -1px;
    background: rgb(213, 213, 213);
    background: linear-gradient( to bottom, rgba(213, 213, 213, 0) 0%, rgb(213, 213, 213) 8%, rgb(213, 213, 213) 92%, rgba(213, 213, 213, 0) 100%);
    z-index: 5;
  }
}

.c-item-list__item {
  padding: 1em 0;
  .c-item-list__hexa {
    width: 32px;
    height: 20px;
    position: absolute;
    background: #333;
    z-index: 5;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -30px;
    margin-top: 0;
  }
  .c-item-list__hexa:before {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 15px solid #f0f0f0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    top: -15px;
  }
  .c-item-list__hexa:after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 15px solid #f0f0f0;
    bottom: -15px;
  }
  .c-item-list__hexa:before {
    border-bottom: 8px solid #333;
    border-left-width: 16px;
    border-right-width: 16px;
    top: -8px;
  }
  .c-item-list__hexa:after {
    border-left-width: 16px;
    border-right-width: 16px;
    border-top: 8px solid #333;
    bottom: -8px;
  }
  .direction-r {
    float: none;
    width: 100%;
    text-align: center;
  }
  .c-item__icon-wrapper {
    text-align: center;
    position: relative;
    i {
      font-size: 16px;
      color: white;
    }
  }
  .title-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
    line-height: 1em;
  }
  .title {
    background: #333;
    display: inline-block;
    padding: 10px;
    font-size: 15px;
    border-radius: 4px;
    color: #fff;
    font-weight: 400;
  }
  .description {
    position: relative;
    z-index: 15;
    font-weight: bold;
    padding-left: 10px;
  }
  .direction-r .title-wrapper {
    float: none;
  }
  .direction-l .description,
  .direction-r .description {
    position: relative;
    z-index: 15;
  }
}

@media (min-width: 768px) {
  .c-item-list {
    &__item {
      content: "";
      display: block;
      height: 0;
      clear: both; // visibility: hidden;
    }
  }
  .c-item-list__item {
    .direction-l {
      margin-right: 20px;
      position: relative;
      width: auto;
      float: right;
      text-align: right;
      .c-item-list__hexa {
        right: -36px;
        left: auto;
        top: 2px;
      }
    }
    .direction-r {
      margin-left: 20px;
      position: relative;
      width: auto;
      float: left;
      text-align: left;
      .c-item-list__hexa {
        left: -36px;
        right: auto;
        top: 2px;
      }
    }
    .c-item__icon-wrapper {
      display: inline-block;
    }
    .title-wrapper {
      display: inline;
      vertical-align: middle;
      margin: 0;
    }
    .direction-l .title-wrapper {
      float: left;
      margin-right: 10px;
    }
    .direction-r .title-wrapper {
      float: right;
      margin-left: 10px;
    }
    .title {
      padding: 5px 10px;
    }
  }
}

@media (max-width: 768px) {
  .c-item-list {
    margin-left: 0px;
    margin-top: 30px;
    &:before {
      left: 50%;
    }
  }
  .c-item-list__item {
    .description {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}