@import "./styles/_template";
@import "./devices.css";

body {
  background-color: #fafafa;
  font-family: Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

.content h2 {
  margin-bottom: 0px;
}

.navbar {
  &.invert {
    .navbar-menu {
      a.navbar-item {
        &--classic {
          &:hover,
          &.is-active {
            background: rgba(0, 0, 0, 0.1);
          }
        }

        &--tab {
          border-color: white;
        }

        &--button {
          span {
            color: white;
          }
        }
      }
    }
  }

  .navbar-menu {
    background-color: transparent;

    a.navbar-item {
      &--classic {
        &:hover,
        &.is-active {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &--tab {
        border-bottom-width: 2px;
        border-bottom-style: solid;

        &:hover,
        &.is-active {
          border-bottom-color: white;
          background: transparent;
        }
      }

      &--button {
        background-color: white;
        border-radius: 4px;
        margin-left: 4px;
        margin-bottom: 4px;

        &:hover,
        &.is-active {
          opacity: 0.7;
        }
      }
    }
  }
}

.translucent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.1;
}

.image {
  &--gallery {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 0;
    cursor: pointer;
    padding-top: 100%;
  }

  &--16_9 {
    padding-top: 56.25%;
  }

  &--4_3 {
    padding-top: 75%;
  }

  &--square {
    padding-top: 100%;
  }
}

.hero-body .image {
  margin: 0 auto;
}

.hero-body.hero-body--absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section--HeroImage > .hero-body {
  padding: 0;
}

.section--dark {
  color: white;

  .title,
  .subtitle {
    color: white;
  }
}

/********* Speakers *********/

.alphabet {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 8px;
}

.alphabet-letter {
  flex: 1;
  flex-basis: 0;
  padding: 4px;
  border: 1px solid #ddd;
  /* border: 1px solid rgb(38, 183, 138); */
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  background-color: #eee;
  margin: 1px;
  text-align: center;
  min-width: 32px;
  min-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
}

.alphabet-letter:focus {
  outline: 0;
}

.alphabet-letter:disabled {
  cursor: initial;
  opacity: 0.35;
}

.speaker-grid {
  display: flex;
  flex-wrap: wrap;
}

.container-speaker {
  padding: 8px;
  font-family: "Poppins", Arial, san-serif;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.ms-speakers__card {
  width: 100%;
  overflow: hidden;
  /* padding: 10px 10px; */
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 10px 1px rgba(60, 60, 60, 0.15);
}

@media (max-width: 767px) {
  .speaker-grid .container-speaker {
    width: 50%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .speaker-grid .container-speaker {
    width: 33%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 25%;
  }
}

@media (min-width: 1024px) and (max-width: 1407px) {
  .speaker-grid .container-speaker {
    width: 25%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 16.66%;
  }
}

@media (min-width: 1408px) and (max-width: 1507px) {
  .speaker-grid .container-speaker {
    width: 16.66%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 12.5%;
  }
}

@media (min-width: 1508px) {
  .speaker-grid .container-speaker {
    width: 12.5%;
  }

  .speaker-grid.max-10 .container-speaker {
    width: 10%;
  }
}

@media (max-width: 480px) {
  .menuTitle h2 {
    font-size: 15px;
    padding: 5px 10px;
  }

  .navbar {
    .navbar-menu {
      a.navbar-item {
        &--button {
          margin-right: 12px;
          margin-left: 12px;
        }
      }
    }
  }
}

.ms-speaker__card__image {
  /* width: 80px; */
  /* border-radius: 50%; */
  /* margin: auto auto 15px; */
  /* height: 80px; */
  width: 100%;
  padding-bottom: 100%;
  display: block;
  background-size: cover;
  background-position: center;
}

.ms-speaker__card__section {
  padding: 8px;
}

.ms-speakers__title {
  font-size: 14px;
  font-weight: 500;
}

.ms-speakers__details {
  font-size: 12px;
  color: rgba(31, 16, 16, 0.65);
}

/********* Services ********/

.ms-services {
  text-align: center;
}

.ms-services h1 {
  font-size: 20px;
  font-weight: bold;
}

.navbar-brand {
  align-items: center;
}

.navbar-brand .navbar-item {
  padding-top: 0;
  padding-bottom: 0;

  img {
    max-height: 52px;
  }
}

.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition: border-bottom-color 250ms ease-in-out;
  background-color: #333;
}

.navbar:not(.is-at-top) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.c-richtext.content {
  margin-bottom: 0;
}

.c-richtext.content {
  p {
    margin-bottom: 0;
  }

  ul {
    margin-top: 0;
  }
}

.c-item {
  &--partner {
    .image {
      padding: 12px;
      width: 100%;
    }

    &--card {
      border: 1px solid #ddd;
      border-radius: 4px;

      .image-container {
        height: 200px;
        flex-direction: column;
        display: flex;
        justify-content: center;
      }

      .title {
        font-size: 1.25rem;
        padding: 0.2em;
      }
    }
  }
}

.c-searchbar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2em;

  &__label {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .control span.icon.is-left {
    color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .control.has-icons-left > input {
    padding-left: 3.25em;
  }

  .control.has-icons-left > input:focus {
    box-shadow: none;
  }

  .control.has-icons-left .input:focus ~ .icon {
    color: white !important;
  }

  .dropdown-trigger > .button {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    display: block;

    .dropdown {
      width: 100%;
      margin: 10px 0px;
    }

    .dropdown-trigger {
      width: 100%;

      .button {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.dropdown-item.dropdown-header {
  padding-bottom: 0;
}

/* show something for undefined icons ? */

i.fa.fa-undefined:before {
  content: "+";
}

.columns:after {
  content: "";
  display: block;
  clear: both;
}

footer.footer {
  background-color: #333;
  padding: 10px;
}

$arrow-gap: 11px;
$arrow-size: 40px;

.c-arrow--scroll-to-top {
  right: $arrow-gap;
  bottom: $arrow-gap;
  width: $arrow-size;
  height: $arrow-size;
  line-height: $arrow-size;
  z-index: 100;
  border-radius: 50%;
  background-color: #333;
  cursor: pointer;
  text-align: center;
  position: fixed;
}

.semantic_ui_range_inner > div:first-child {
  background-color: #ddd !important;
}

// divider-fields
.df-field {
  display: table;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;

  &__label {
    display: table-cell;
    vertical-align: top;
    width: 50px;
    font-size: 0.75rem;
    color: black;
    font-weight: 700;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__value {
    display: table-cell;
  }
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  /*16:9*/
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 480px) {
  .section--VideoSection {
    padding-left: 0;
    padding-right: 0;
  }
}

// gallery margin
.column-margin {
  &__none {
    padding: 0px;
  }

  &__normal {
    padding: 0.75em;
  }

  &__large {
    padding: 1.5em;
  }
}

.mobile-apps-buttons {
  margin-top: 40px;

  a {
    margin-right: 10px;
  }

  &.top {
    display: flex;
    justify-content: center;
  }
}

.tabs.is-centered.is-fullwidth {
  ul {
    flex-shrink: 1;
  }

  li {
    flex-shrink: 1;
  }
}

.tab--container {
  text-align: center;
}

.c-program-table {
  // display: table;
  width: 100%;

  & > div:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 1.1em;

    &__time {
      width: 140px;
      flex-grow: 0;
      padding: 4px 8px;
      padding-left: 0;
    }

    &__description {
      flex: 1;
      padding: 4px 8px;
      padding-right: 0;
    }
  }
}

.list-section {
  &__icon {
    font-size: 30px;
    margin-right: 5px;
  }

  &__social {
    font-size: 30px;
    margin: auto;
    text-align: center;
  }

  &__image {
    margin: auto;
  }
}

/* Responsive bulma tabs */
// @media screen and (max-width: 768px) {
//   li:not(.is-active) > a > span:not(.icon) {
//     visibility: hidden;
//     position:  absolute;
//   }

//   .tabs li:not(.is-active) {
//     -webkit-box-flex: 0;
//     -ms-flex-positive: 0;
//     flex-grow: 0;
//     -ms-flex-negative: 1;
//     flex-shrink: 1;
//   }

//   .tabs .icon {
//     margin-left: 0.5em;
//   }
// }

// .tabs li {
//   transition: flex-grow 1s ease;
// }

@media (min-width: 481px) {
  .c-text--desktop {
    display: inline-block;
  }

  .c-text--mobile {
    display: none;
  }

  .tab--container {
    image: {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .c-text--desktop {
    display: none;
  }

  .c-text--mobile {
    display: inline-block;
  }

  .c-richtext.content.right > div p,
  .c-richtext.content.left > div p {
    text-align: center !important;
  }

  .c-richtext.content.description {
    text-align: center !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .c-item-list--right:before,
  .c-item-list--left:before {
    content: none !important;
  }

  footer.footer {
    .section--ListSection {
      .c-richtext.content {
        text-align: center !important;
      }
    }
  }
}

.c-richtext.content.right p {
  text-align: right !important;
}

.c-richtext.content.left p {
  text-align: left !important;
}

.c-opportunity {
  &.horizontal {
    display: flex;
    align-items: center;
    justify-content: center;

    .border {
      width: 6px;
      top: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  &.vertical,
  &.simple {
    .border {
      height: 6px;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &.simple {
    padding: 0px;

    .fields-container {
      margin: 0px !important;
      padding: 6px 4px;
      color: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      div {
        justify-content: center;
        font-weight: bold;
      }
    }

    .image-container {
      margin-top: 6px;
      height: 160px;
      flex-direction: column;
      display: flex;
      justify-content: center;
    }
  }

  &.details {
    border-radius: 0;
    margin: -12px 0px;
    display: flex;
  }

  .c-dropzone {
    display: flex;
  }
}

.column .c-opportunity {
  width: 100%;
}

.column.is-equal-height {
  display: flex;
}

.c-opportunity-details {
  h2.title {
    margin: 0;
  }

  .box {
    box-shadow: 0 2px 15px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }

  .box.header {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .back-button:hover {
    text-decoration: underline;
  }

  .left-border {
    position: absolute;
    width: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    top: 0px;
    left: 0px;
    bottom: 0px;
  }
}

.image-wrapper--top {
  margin-bottom: 16px;
}

.item--card {
  .item__image {
    margin: -20px -20px 12px -20px;
  }
}

@media (max-width: 480px) {
  .c-opportunity-details .box.header {
    display: block;
  }

  .c-opportunity.horizontal {
    display: block;

    .image-container > img {
      margin-bottom: 10px !important;
    }
  }

  .field--details {
    display: block !important;
  }
}

.navbar.secondary {
  position: absolute;
  right: 50px;
  height: 50px !important;
  background-color: transparent;
  border: none;

  a.navbar-item > span {
    color: white;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #bbb;
  }

  a.navbar-item.is-active > span {
    filter: brightness(90%);
  }

  a.navbar-item:hover,
  a.navbar-item.is-active {
    background: transparent !important;
  }
}

.field--details {
  display: flex;
  margin-bottom: 8px;
}

.divider--colorized {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

figure > img {
  &.is-rounded-corner {
    border-radius: 8px;
  }
}

.card {
  > .card-image {
    & > figure.image > img {
      object-fit: cover;
    }

    & > figure.image > .icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      font-size: 4rem;
    }
  }

  figure {
    &.image.image--color-overlay::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .media-content {
    .subtitle:not(.is-spaced) + p {
      margin-top: -1.25rem;
    }
  }

  > .card-content {
    a {
      word-break: break-all;
    }
  }

  &.card--none {
    background: transparent;
    border: 0;
    box-shadow: none;

    > .card-content {
      padding: 0;
    }
  }
}

.section {
  &.is-full-width {
    padding-left: 0;
    padding-right: 0;

    > .container {
      width: 100%;
      max-width: 100%;
    }
  }

  &.section--no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header-menu-is-shown {
  position: relative;
  padding-top: 40px;
}

.header-menu-is-shown .navbar-item.menu-settings {
  color: white;
  position: absolute;
  top: 4px;
  right: 0;
  padding: 0;
  font-size: 15px;
}

.header-menu {
  &--social-media {
    font-size: 1.22em;
    margin-right: 10px;
  }

  &--language {
    margin-bottom: 5px;
  }

  &--button {
    font-size: 15px !important;
    margin-left: 4px;
  }
}

.mobile-footer-button {
  display: none;
}

@media (max-width: 480px) {
  .header-menu-is-shown {
    position: unset;
    padding-top: 0px;

    .navbar-item.menu-settings {
      padding: 4px 12px;
      position: relative;
    }
  }

  .header-menu {
    &--social-media {
      font-size: 1.22em;
      margin-right: 10px;
      padding: 0 5px 0 5px;
    }

    &--container {
      display: flex;
      flex-direction: column-reverse !important;
    }

    &--button {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }

  .container--has-footer-button {
    padding-bottom: 61px;
  }

  .mobile-footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 -5px 5px -5px #333;
  }
}

@media (max-width: 480px) {
  .container--has-footer-button {
    padding-bottom: 61px;
  }
}

.mobile-footer-button {
  display: none;
}

@media (max-width: 480px) {
  .mobile-footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 -5px 5px -5px #333;
  }
}

.column {
  &.force-flex-grow {
    flex-grow: 1 !important;
  }

  &.column--no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.columns,
.columns.is-gapless {
  > .column {
    &.column--extra-padding {
      padding: 32px 72px !important;

      @media (max-width: 720px) {
        padding: 24px !important;
      }
    }
  }
}

.section--no-vertical-padding {
  .columns,
  .columns.is-gapless {
    > .column:not(.column--extra-padding) {
      padding: 0 !important;
    }
  }
}

.react-parallax > img {
  object-fit: cover;
}

.add-section {
  &.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-weight: bold;
    flex-direction: column;
    text-align: center;
    line-height: 3;
    font-size: 30px;
  }

  &.button-only {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    height: 80px;
  }
}
