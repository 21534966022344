.vertical--timeline {
    width: 100%;
    margin: 30px auto;
    position: relative;
    transition: all 0.4s ease;
  
    &:before {
      content: "";
      width: 3px;
      height: 100%;
      left: 50%;
      top: 0;
      position: absolute;
    }
  
    &:after {
      content: "";
      clear: both;
      display: table;
      width: 100%;
    }
  
    &__item {
      margin-bottom: 40px;
      position: relative;
  
      &:before,
      &:after {
        content: "";
        display: block;
        width: 100%;
        clear: both;
      }
  
      &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        width: 54px;
        height: 54px;
        position: absolute;
        top: 0;
        left: 50%;
        overflow: hidden;
        margin-left: -25px;
        border-radius: 50%;
      }
  
      &--content {
        display: flex !important;
  
        .mainside {
          width: 45%;
          background: #fff;
          box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          transition: all 0.3s ease;

          &__arrow {
            position: absolute;
            top: 20px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;

            &--left {
              left: 45%;

            }
            &--right {
              right: 45%;
              border-left: 0;
              border-right: 7px solid black;
            }
          }
  
          &__title {
            padding: 5px 15px;
            color: #fff;
            font-weight: 300;
            border-radius: 3px 3px 0 0;
          }
  
          &__button {
            padding: 5px 15px;
            text-decoration: none;
            background: transparent;
            display: inline-block;
            position: relative;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: bold;
            border-radius: 5px;
            border: 2px solid black;
            box-shadow: 2px 2px 0 black;
          }
  
          // &:before {
          //   content: "";
          //   position: absolute;
          //   left: 45%;
          //   top: 20px;
          //   width: 0;
          //   height: 0;
          //   border-top: 7px solid transparent;
          //   border-bottom: 7px solid transparent;
          // }
  
          &:not(.right) {
            >.mainside__arrow--right {
              display: none;
            }
          }
          &.right {
            float: right;
            >.mainside__arrow--left {
              display: none;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .vertical--timeline {
      margin: 15px;
      padding: 0;
  
      &:before {
        left: 0;
      }
  
      &__item {
        &--icon {
          left: 0;
        }
        &--content {
          display: block !important;
          .mainside {
            width: 90%;
            float: right;

            &.right, &:not(.right) {
              >.mainside__arrow--left {
                display: none;
              }
              >.mainside__arrow--right {
                display: block;
                left: 10%;
                margin-left: -6px;
                border-left: 0;
              }
            }

          }
          .otherside {
            display: none !important;
          }
        }
      }
    }
  }