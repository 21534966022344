.card {
  &.card--metro {
    background: transparent;
    border: 0;
    box-shadow: none;

    .card-content {
      padding: 1rem;
    }

    .title {
      font-weight: bold;
    }
  }

  &.card--content-inside {
    .card-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

      .subtitle {
        color: white;
      }
    }
  }
}
